<template>
  <div class="download mywidth box-bg">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="title" label="名称" > </el-table-column>
      <el-table-column prop="infoa" label="使用范围" > </el-table-column>
      <el-table-column prop="urlname" label="操作">
       <template slot-scope="scope">
        <el-button @click="getdown(scope.row.urlname)" type="text" >免费下载</el-button>
      </template>

     </el-table-column>
      <div class="zanwu" v-if="tableData&&tableData.length==0 ">
        暂无数据
      </div>
    </el-table>
  </div>
</template>

<script>
import { moretooldownload } from "@/api/download.js";
export default {
  name: "download",
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getmore()
  },
  methods: {
    getmore() {
      moretooldownload().then((data) => {
        console.log(data, "414");
        this.tableData = data.tdata
      });
    },
      getdown(val){
      window.open(val,'')
    },
  },
};
</script>

<style lang="less" scoped>
.download {
  margin: 20px auto;
  padding: 30px;
}
</style>